import React from 'react';
import Routes from './routes/Routes';
import { Provider } from 'react-redux';
import { store, persistor } from './utils/store';
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react';

//Assets
import 'antd/dist/antd.css';

function App() {
  //hola
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Routes />
        </Router>
      </PersistGate>
    </Provider>
  )
}

export default App;
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux'
import { useHistory } from "react-router-dom";
import Api from '../../utils/api';

//Components
import { message, Spin, Card, Table, Tag, Button } from 'antd';
import ModalSurvey from '../../components/ModalSurvey/ModalSurvey';

//Assets
import './survey.scss';

const api = new Api()

function Survey() {
  const history = useHistory();
  const user = useSelector(state=>state.usuario)

  const [loading, setLoading] = useState(false)
  const [surveys, setSurveys] = useState([])
  const [typeModal, setTypeModal] = useState('Crear')

  const [modal, setModal] = useState(false)

  const handleClick = (id) =>{
    history.push(`/survey/detail/${id}`)
  }

  const handleCreate = (id) =>{
    setTypeModal('Crear')
    setModal(true)
  }

  const handleEdit = (id) =>{
    setTypeModal('Editar')
    setModal(true)
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Pregunta',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
      render: status => (
        <>
          {
              status? <Tag color='green' key={status}>Activa</Tag> : <Tag color='volcano' key={status}>Inactiva</Tag>
          }
        </>
      )
    },
    {
      title: 'Opcional',
      dataIndex: 'optional',
      key: 'optional',
      render: optional => (
        <>
          {
              optional? <Tag color='geekblue' key={optional}>Si</Tag> : <Tag color='blue' key={optional}>No</Tag>
          }
        </>
      )
    },
    {
      title: 'Respuestas',
      dataIndex: 'answer',
      key: 'answer',
      render: answer => (
        <>
          {
              answer.length
          }
        </>
      )
    },
    {
      title: 'Acciones',
      dataIndex: '',
      key: '',
      render: elm => (
        <>
          {<>
            <Button type="primary" shape="round" onClick={()=>handleEdit(elm.id)}>Editar</Button>
            &nbsp;
            <Button type="primary" shape="round" onClick={()=>handleClick(elm.id)}>Ver</Button>    
            </>}
        </>
      )
    }
  ]

  useEffect(() => {
      setLoading(true)
      api.surveyAll(user.token)
      .then(res=>{
        if(res.success){
            //setSurveys(res.surveys)
            setSurveys(res.success)
            console.log(res.success)
        }
        else{
          message.error(res.error);
        }
      })
      .catch( err => message.error(err) )

      .finally( end => setLoading(false) )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="Survey">
        {
            loading ?
            <div className="Loading">
              <Spin size="large" />
            </div>
            :
            <div className="SurveyCard">
            <Card>
              <div className="SurveyHead">
                <div>
                  <Button type="primary" shape="round" onClick={handleCreate}>Nueva Encuesta</Button> 
                </div>
              </div>
              <Table columns={columns} dataSource={surveys} />
            </Card>
            </div>
        }
        <ModalSurvey modal={modal} data={typeModal} onChangeState={newState=>setModal(newState)} />
    </div>
  )
}

export default Survey;

// import { store } from '../store'
const base = process.env.NODE_ENV==='development'?'https://sandbox.tokencash.mx/api':'https://api.tokencash.mx/api';
const url_pyxter = 'https://desarrollo.api.tc';
const axios = require('axios').default;


export default class Api {
  constructor() {
    this.http = new Http()
  }

  login(body, token = '') {
      return this.http.post('mobile/business/v1/login', body, token);
  }

  getSellers(token) {
      return this.http.get('mobile/business/v1/get_sellers', token);
  }

  createToken(body, token) {
      return this.http.post('mobile/business/v1/create_token', body, token);
  }

  updateToken(body, token){
    return this.http.post('mobile/business/v1/search_token', body, token)
  }




  dashboard(token){
    return this.http.get('dashboard_new_users', token)
  }

  dashboardUsers(token){
    return this.http.get('dashboard_users', token)
  }

  /*
  * Tokens
  */
  getKey(data){
    return this.http.getPyxter(`/authentication?account=${data.account}&apikey=${data.apikey}`)
  }

  generateToken(body, token){
    return this.http.post('getToken', body, token)
  }




  /*
  * Users
  */
  users(token){
    return this.http.get('users', token)
  }

  userCreate(token, body){
    return this.http.post('users/create', body, token)
  }


  /*
  * Survey
  */

  surveyAll(token){
    return this.http.get('survey', token)
  }

  surveyDetail(token, id){
    return this.http.get(`survey/result/${id}`, token)
  }
  
  logout(body, token = '') {
    return this.http.post('', body, token)
  }

}

class Http {
  get(url, token = '') {
    return fetch(`${base}/${url}`, {
      method: 'GET', // or 'PUT'

      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        //'x-auth-token': token
        authorization: token ? `Bearer ${token}` : '',
      },
    })
      .then(res => {
        //console.log('Http:res', res)
        // if (res.status === 401) {
        //   store.dispatch({
        //     type: 'LOGOUT',
        //   })
        //   return res.json()
        // }
        // if (res.headers.get('authorization')) {
        //   store.dispatch({
        //     type: 'SET_TOKEN',
        //     payload: {
        //       token: res.headers.get('authorization').replace('Bearer ', ''),
        //     },
        //   })
        // }
        return res.json()
      })
      .catch(err => console.warn('Http:err', err.message))
  }

  getPyxter(url) {
    axios.get(`${url_pyxter}${url}`)
      .then(function (response) {
        // handle success
        return response.json()
      })
      .catch(err => console.warn('Http:err', err.message))
  }


  postPyxter(url, body, token) {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Authorization': token.jwt
    }
    return fetch(`${url_pyxter}${url}`, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(body), // data can be `string` or {object}!
      headers: headers
    })
      .then(res => {
        return res.json()
      })
      .catch(err => console.warn('Http:err', err.message))
  }




  post(url, body, token = '') {
    //console.log(`${base}/${url}`)
    const headers = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
    }
    if (token) {
      headers.authorization = `Bearer ${token}`
    }
    return fetch(`${base}/${url}`, {
      method: 'POST', 
      body: JSON.stringify(body), 
      headers,
    })
      .then(res => {
        return res.json()
      })
      .catch(err => console.warn('Http:err', err.message))
  }

  postBloob(url, body, token = '') {
    //console.log(`${base}/${url}`)
    const headers = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
    }
    if (token) {
      headers.authorization = `Bearer ${token}`
    }
    return fetch(`${base}/${url}`, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(body), // data can be `string` or {object}!
      headers,
    })
      .then(res => {
        //console.log('Http:res', res)
        return res.blob()
      })
      .catch(err => console.warn('Http:err', err.message))
  }

  postMultipart(url, body, token = '') {
    //console.log(`${base}/${url}`)
    const headers = {
    }
    if (token) {
      headers.authorization = `Bearer ${token}`
    }
    return fetch(`${base}/${url}`, {
      method: 'POST', // or 'PUT'
      body: body, // data can be `string` or {object}!
      headers,
    })
      .then(res => {
        //console.log('Http:res', res)
        return res.json()
      })
      .catch(err => console.warn('Http:err', err.message))
  }
}

import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import Api from '../../utils/api';

//Components
import { Button, message, Space, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Tokencard from '../../components/TokenCard/Tokencard';

//Assets
import './tokens.scss';

function Tokens(){
    const api = new Api();
    const token = useSelector(state=>state.keys);

    const { Option } = Select;

    const establishment = useSelector(state=>state.establishment)
    const dispatch = useDispatch();
    const [mount, setMount] = useState('');
    const [nip, setNip] = useState('');
    const [sell, setSell] = useState('');
    const [tokens, setTokens] = useState([]);
    const [sellers, setSellers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading_close, setLoginClose] = useState(false);
    

    const list_tokens = tokens?.map( (token) => <Tokencard key={token.transaction} token={token.token} transt={token.transaction} amount={token.amount}/>)

    const list_sellers = sellers?.map((seller) => <Option value={seller.nombre}>{seller.nombre}</Option>)

    useEffect(()=>{
        api.getSellers(establishment.token)
        .then(res =>{    
            if(res.success){
                setSellers(res.data)       
            }
            else{
                message.error(res.message);
            }
        })
        .catch( err => message.error('Error de conexión.') )
    
        .finally( end => setLoading(false) )

    },[]);

    const createToken = () => {
        if(mount === '' || mount === '0' || nip === '', sell === ''){
            message.error('Ingrese datos validos.');
        }
        else{
        setLoading(true);

        const data = {
            monto: mount,
            nip: nip,
            reference: JSON.stringify({
                'type': 'gas station',
                'name': sell,
                'ticket': '1234',
                'sale_price': '0',
                'unit_price': '0',
                'product': 'BI',
                'quantity': '1',
                'others': '',
                'cash_register': '1'
            }),
        };

        api.createToken(data, establishment.token)
        .then(res =>{    
            if(res.status === 'success'){
                let tok = {
                    token: res.data.token,
                    transaction: res.data.transaction,
                    amount: mount,
                    sale: 0
                };
                setTokens([tok,...tokens])        
            }
            else{
                message.error('No se pudo crear el token, intente más tarde.')
            }
        })
        .catch( err => message.error('Error de conexión.') )
    
        .finally( end => setLoading(false) )
    }
    };
    


  return (
    <>
    <Helmet><title>Generar Token | Tokencash Hub</title></Helmet>
    <div className="Generate">
        <div className="SecGen">
            <div className="tittok">
                <h1 className="TitleTok">GENERAR TOKEN</h1>
            </div>
            <div className="bodytok">
                <h6>Vendedor</h6>
                <Select className="SelectSeller" onChange={(value) => setSell(value)}>
                    {list_sellers}
                </Select>
                <h6>Monto a cobrar</h6>
                <input className="InputMount" placeholder="$100.00" value={mount} onChange={(e)=>{setMount(e.target.value)}} type="number" ></input>
                <h6>Nip</h6>
                <input className="InputMount" placeholder="1111" value={nip} onChange={(e)=>{setNip(e.target.value)}} max="4" type="number" ></input>
                <div className="divbtn">
                    <Space>
                        <Button onClick={createToken} type="primary" size="large" disabled={ loading ? 'disabled' : '' }>{ loading ? <LoadingOutlined /> : 'GENERAR TOKEN' }</Button>
                        {/*
                        <Button onClick={()=>{setLoginClose(true)}} type="danger" size="large" disabled={ loading_close ? 'disabled' : '' }>{ loading_close ? <LoadingOutlined /> : 'CORTE DE PAGOS' }</Button>
                    */
                    }
                        </Space>
                </div>
            </div>
        </div>
        <div className="SecListTok">
            <h5 className="TitCosltTok">CONSULTAR TOKENS</h5>
                {
                    tokens.length < 1 ? 
                        <div className="Nohay">
                            <h5>No hay tokens generados</h5>
                        </div>
                    :
                        list_tokens
                }
            </div>
    </div>
    </>
  );
}

export default Tokens;

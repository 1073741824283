import Login from '../features/LoginFeatures/Login';
import GenerateToken from '../features/PaymentFeatures/Tokens';
//import Home from '../features/HomeFeatures/Home';
//import Status from '../features/StatusFeatures/Status';
import Survey from '../features/SurveyFeatures/Survey';
import SurveyDetail from '../features/SurveyFeatures/SurveyDetail';
import SurveyIA from '../features/SurveyFeatures/SurveyIA';
//import User from '../features/UserFeatures/User';
//import UserApp from '../features/UserFeatures/UsersApp';

const Routes = [
    {
        path: '/',
        exact: true,
        component: Login,
        name: 'login',
        protected: false,
    },
    {
        path: '/generartoken',
        exact: true,
        component: GenerateToken,
        name: 'generartoken',
        protected: true,
    },
    /*
    {
        path: '/usuarios',
        exact: false,
        component: User,
        name: 'user',
        protected: true, 
    },
    
    {   
        path: '/home',
        exact: true,
        component: Home,
        name: 'home',
        protected: true,
    },
    {
        path: '/userapp',
        exact: true,
        component: UserApp,
        name: 'userapp',
        protected: true,
    },
    {   
        path: '/status',
        exact: true,
        component: Status,
        name: 'status',
        protected: false,
    },
    */
    {
        path: '/encuestas',
        exact: true,
        component: Survey,
        name: 'survey',
        protected: true,
    },
    {
        path: '/survey/detail/:slug',
        exact: false,
        component: SurveyDetail,
        name: 'surveydetail',
        protected: true, 
    },
    {
        path: '/survey/ia',
        exact: false,
        component: SurveyIA,
        name: 'surveyia',
        protected: true, 
    },
    /*
    {
        path: '/chat',
        exact: false,
        component: ChatSoport,
        name: 'chat',
        protected: false, 
    },
    
    {
        path: '/users',
        exact: true,
        component: User,
        name: 'user',
        protected: true,
    },
    {
        path: '/users/list',
        exact: true,
        component: UserList,
        name: 'user',
        protected: true,
    },
    {
        path: '/users/:slug',
        exact: true,
        component: UserDetail,
        name: 'user',
        protected: true,
    },
    */
];

export default Routes;

const initState = () => ({
    jwt: ''
})


function keys(state = initState(), action) {
    
    switch (action.type) {
        case 'KEYS':
            return action.payload
        case 'LOGOUT':
            return initState
        default:
            return state
    }
}


export default keys;
import React, {useEffect} from 'react';
import Chart from 'chart.js';


//Components


//Assets
import './surveyIA.css';

function SurveyIA() {
//GENERO
const infoA = ['Hombres', 'Mujeres', 'Otros'];
const infoA1 =  [61.13, 16.53, 22.34];

//EDAD
const infoB = ['18-20', '21-30', '31-40', '41-50', '51-60', '61-70', '71-80'];
const infoB1 =  [4.00, 39.00, 43.00, 4.00, 6.00, 4.00, 1.00];

//TIPO DE VEHICULO
const infoC = ['Particular', 'Empresarial', 'Uber', 'Otro'];
const infoC1 =  [37.80, 7.80, 46.70, 7.70];

//CERCANÍA AL ESTABLECIMIENTO
const infoD = ['Cercano', 'Regular', 'De paso', 'Alejado'];
const infoD1 =  [78.23, 7.00, 12.80, 1.97];

//TIPO DE USUARIO
const infoE = ['Recurrentes', 'Regulares', 'Nuevos'];
const infoE1 =  [87.90, 10.10, 2.00];

useEffect(() => {
    let colors = ['#FB9224', '#FBC02D','#E53935', '#F48FB1',  '#BA68C8', '#90CAF9', '#18FFFF', '#009688', '#FF8A65', '#8D6E63'];
 
       const ctx = document.getElementById('myChart');
       const ctx2 = document.getElementById('myChart2');
       const ctx3 = document.getElementById('myChart3');
       const ctx4 = document.getElementById('myChart4');
       const ctx5 = document.getElementById('myChart5');

       new Chart(ctx, {
         type: 'doughnut',
         data: {
             label: '',
             labels: infoA,
             datasets: [{
                 data: infoA1,
                 borderColor: colors,
                 backgroundColor: colors
             }],
         },
         options: {
             responsive: true
         }
     })

     new Chart(ctx2, {
        type: 'doughnut',
        data: {
            label: '',
            labels: infoB,
            datasets: [{
                data: infoB1,
                borderColor: colors,
                backgroundColor: colors
            }],
        },
        options: {
            responsive: true
        }
    })

    new Chart(ctx3, {
        type: 'doughnut',
        data: {
            label: '',
            labels: infoC,
            datasets: [{
                data: infoC1,
                borderColor: colors,
                backgroundColor: colors
            }],
        },
        options: {
            responsive: true
        }
    })

    new Chart(ctx4, {
        type: 'doughnut',
        data: {
            label: '',
            labels: infoD,
            datasets: [{
                data: infoD1,
                borderColor: colors,
                backgroundColor: colors
            }],
        },
        options: {
            responsive: true
        }
    })

    new Chart(ctx5, {
        type: 'doughnut',
        data: {
            label: '',
            labels: infoE,
            datasets: [{
                data: infoE1,
                borderColor: colors,
                backgroundColor: colors
            }],
        },
        options: {
            responsive: true
        }
    })
       // eslint-disable-next-line react-hooks/exhaustive-deps
     },[])

  return (
    <div className="SurveyIA">
        <div className='DataBaseA'>
            <div className='CardBaseData'>
                <h2>Género</h2>
                <canvas id="myChart"></canvas>
            </div>
            <div className='CardBaseData'>
                <h2>Edad</h2>
                <canvas id="myChart2"></canvas>
            </div>
            <div className='CardBaseData'>
                <h2>Tipo vehículo</h2>
                <canvas id="myChart3"></canvas>
            </div>
        </div>
        <div className='DataBaseB'>
            <div className='CardBaseData'>
                <h2>Cercanía al establecimeinto</h2>
                <canvas id="myChart4"></canvas>
            </div>
            <div className='CardBaseData'>
                <h2>Tipo de usuario</h2>
                <canvas id="myChart5"></canvas>
            </div>
        </div>
    </div>
  )
}

export default SurveyIA;

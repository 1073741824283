import React, { useState } from 'react';
import { Modal, Input, DatePicker, Switch, message, Button, Table } from 'antd';

//Assets
import './modalSurvey.css'


function ModalSurvey(props) {
  const {modal, data} = props;

  const { RangePicker } = DatePicker;
  const [survey, setSurvey] = useState([]);
  const [answers, setAnswers] = useState([]);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Respuesta',
      dataIndex: 'res',
      key: 'res',
    },
    {
      title: 'Acciones',
      dataIndex: '',
      key: '',
      render: elm => (
        <>
        {
            <Button type="primary" shape="round" onClick={()=>deleteAns(elm)}>Eliminar</Button>
        }
        </>
      )
    }
  ];

  const sendSurvey = () => {
    message.error('error')
  }

  
  const addAns = () => {
    const text =  document.querySelector("#anstext");

    if(text.value === ''){
      message.error('Ingrese datos para continuar...')
      return
    }

    var ams = {id: answers.length + 1  ,res: text.value};

    setAnswers([...answers, ams])

    text.value = ''
  };
  
  const deleteAns = (key) => {
    console.log(key)
    console.log(survey)
    alert('tata')
  };
  
  const closeModal = () => { 
    props.onChangeState(false)
    setSurvey([])
    setAnswers([])
  };

  return (
    <>
    <Modal
        title={`${data} Encuesta`}
        centered
        visible={modal}
        onOk={sendSurvey}
        onCancel={closeModal}
        width={1000}>

    <div className="form_modal">
      <h5>Pregunta:</h5>
      <Input placeholder="Pregunta" />
    </div>
    <div className="form_modal">
      <h5>Sub-Pregunta:</h5>
      <Input placeholder="Sub-Pregunta" />
    </div>
    <div className="form_line">
      <div>
      <h5>Fechas:</h5>
      <RangePicker />
      </div>

      <div>
      <h5>Multiple opción:</h5>
      <Switch defaultChecked  />
      </div>

      <div>
      <h5>Estatus:</h5>
      <Switch />
      </div>
    </div>
    <div>
      <h5>Respuestas</h5>
      <Input id="anstext" placeholder="Respuesta" />
      <Button type="primary" onClick={addAns}>Añadir</Button>
    </div>
    <div>
      <Table dataSource={answers} columns={columns} />
    </div>

      </Modal>
    </>
  )
}


export default ModalSurvey;

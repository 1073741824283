import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import LayoutDashboard from '../components/LayoutDashboard/LayoutDashboard';


const AuthRoute = props => {
  const establishment = useSelector(state=>state.establishment);
  const { component, ...rest } = props;
  const Component =  component;
    if (establishment.token === '') return <Redirect to="/" />

  const renderComponent = (componentprops)=>{
    return(
      <LayoutDashboard>
        <Component {...componentprops}></Component> 
      </LayoutDashboard>    
    )
    
  }
  return <Route render={renderComponent} {...rest}/>;
};


export default AuthRoute;
import React from 'react';

//Components
import SideMenu from '../SideMenu/SideMenu';

//Assets
import './layoutDashboard.css'

function LayoutDashboard(props) {
  return (
    <div className="LayoutDashboard">
      <SideMenu />
      <div className="MainDashboard">
        {props.children}
      </div>
    </div>
  )
}


export default LayoutDashboard;

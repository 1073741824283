import React from 'react';
import './optionElement.scss';

function OptionElement(props) {
    const { Icon, Text, Active } = props;

  return (
    <button onClick={() => props.onClick()}  className={Active? 'SideBtnOptionActive':'SideBtnOption'}>
        <i className={Active? `fas ${Icon} IconOptionActive` : `fas ${Icon} IconOption`}></i>
        <h6 className={Active? `TextOptionActive` : `TextOption`}>{Text}</h6>
    </button>
  )
}

export default OptionElement;
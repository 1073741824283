import React, {useEffect} from 'react';
import  Chart from 'chart.js';

import { Card } from 'antd';

//Assets
//import './loading.css'

function Loading(props) {
    const {ans, res } = props
    let answers = []
    let colors = []
    let result = []
    let count = 0
    let answert = 0
    const colores = ['#FF00FB', '#6CA968', '#E6D93B', '#E63B3B']

    const mystyle = {
        width: "30vw",
      };
    
    useEffect(() => {
        ans?.forEach(element => {
            answers.push(element.answer_name)
            colors.push(colores[answert])
            res.forEach(em => {
                if (element.id === em.answer_id) {
                    count++
                }
            })
            result.push(count)
            // eslint-disable-next-line react-hooks/exhaustive-deps
            count = 0
            answert++
        });


        var ctx = document.getElementById('linechart');
        new Chart(ctx, {
            type: 'bar',
            data: {
                labels: answers,
                datasets: [{
                    data: result,
                    fill: false,
                    borderColor: colors,
                    backgroundColor: colors,
                    borderWidth: 1
                }]
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <div className="CardInfo">
        <Card className="CardInfoBody" bordered={false}>
            <canvas style={mystyle} id="linechart"></canvas>
        </Card>
    </div>
  )
}


export default Loading;

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import routeComponets from './ListRoutes';

const Routes = (props) => {

    const routes = () => {
        let routes = [];
        routeComponets.forEach(element => {
            if (element.protected) {
                routes.push(
                    <AuthRoute
                        key={element.name}
                        exact={element.exact}
                        path={element.path}
                        component={element.component}
                        name={element.name}
                    ></AuthRoute>
                )
            } else if (!element.protected) {
                routes.push(
                    <Route key={element.name} exact={element.exact} path={element.path} component={element.component} />
                );
            }
        })

        return routes;
    }
    return (
        <Switch>
            {routes()}
        </Switch>
    )
}

export default Routes
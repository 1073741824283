import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router-dom";
import {Helmet} from "react-helmet";
import Api from '../../utils/api';
import Constants from '../../utils/constants.js';

//Components
import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { message, Tooltip, Badge } from 'antd';

//Assets
import logo from "../../assets/images/logo.png";
import './loginPage.scss';

function Login() 
{
  const api = new Api()
  let history = useHistory();
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');
  const establishment = useSelector(state=>state.establishment)

  const text = <span>Puedes ingresar tu email o username.</span>;

  document.body.onkeyup = (e) => {
    if(e.code === 'Space' || e.code === 'Enter'){
      sendLogin(e)
    }
  }

  useEffect(() => {
    if(establishment.token){
      history.push('/generartoken')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  
  const sendLogin = (e) => {
    e.preventDefault()
    if(account && password){
      setLoading(true)
      let data = {account, password}
      api.login(data)
      .then(res =>{      
        if(res.success){

          dispatch({
            type: 'ESTABLISHMENT',
            payload:{
              id: 1,
              name: '',
              number: account,
              apikey: '',
              token: res.data.access_token
            }
          })

          history.push("/generartoken");
          
       
          
        }
        else{
          message.error(res.message);
        }
      })
      .catch( err => message.error('Error de conexión.') )

      .finally( end => setLoading(false) )
    }
    else{
      message.error('Ingrese su email y contraseña.');
    }
  }

  return (
    <>
    <Helmet><title>Login | Tokencash Hub</title></Helmet>
    <div className="LoginPage">
      <div className="LoginCard">
        <img width="50px" height="60px" alt="Logo_Tokencash" src={logo} />
        <h4 className="Title1">{Constants.titleLogin}</h4>
        <h5 className="Small">{Constants.subTitleLogin}</h5>
        <div className="LoginForm">
          <Tooltip placement="topLeft" title={text}>
            <h5 className="LoginH5">Usuario <Badge count={<QuestionCircleOutlined />} /></h5> 
          </Tooltip>
          <input className="LoginInput" type="text" placeholder="Usuario" onChange={(e)=>{setAccount(e.target.value)}} />
          <h5 className="LoginH5">Contraseña</h5>
          <input className="LoginInput" type="password" placeholder="********" onChange={(e)=>{setPassword(e.target.value)}} />
        </div>
        <button className="LoginBtn" onClick={sendLogin}>{loading? <LoadingOutlined/>: 'INGRESAR'}</button>
      </div>
      <h5 className="Micro">{Constants.footerLogin}</h5>
    </div>
    </>
  );
}

export default Login;
import React from 'react';
import { GoogleMap, LoadScript, Marker, Circle } from '@react-google-maps/api';

//Assets
import './cardMap.scss';

const center = {
  lat: 23.7460465,
  lng: -103.1691196
};

function CardMap(props) {
  const {data_res} = props;

  const options = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.5,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    zIndex: -1
  }

  const mark = data_res?.map((loc) =>
    <Marker
    key={loc?.id}
    position={{lat: parseFloat(loc?.lat_long.split(",")[0]), lng: parseFloat(loc?.lat_long.split(",")[1])}}
    />
  )


  const customer = (
    <>
    <Circle center={{lat: 20.6592392, lng: -103.3846855}} options={options}/>
    <Marker
    position={
      {
      lat: 20.6592392,
      lng: -103.3846855
    }
    }
      label=''
    />
    </>
    )
  

  return(
      <div className="CardMap">
        <LoadScript googleMapsApiKey="AIzaSyBH_G3rKsEu2Fn6LQFM07hQyGO6Lz-Vvso" >
          <GoogleMap
            mapContainerClassName="Map"
            center={center}
            zoom={5}
          >
            <>
            {
              mark
            }
            {
              customer
            }
            </>
          </GoogleMap>
        </LoadScript>
      </div>
  ) 
}
export default CardMap;

const initState = () => ({
    name: '',
    email: '',
    phone: '',
    password: '',
    role: '',
    token: ''
})


function user(state = initState(), action) {
    
    switch (action.type) {
        case 'USERDATA':
            return action.payload
        case 'LOGOUT':
            return initState
        default:
            return state
    }
}


export default user;
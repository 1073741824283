const initState = () => ({
    id: '',
    name: '',
    number: '',
    apikey: '',
    token: ''
})


function establishment(state = initState(), action) {
    
    switch (action.type) {
        case 'ESTABLISHMENT':
            return action.payload
        case 'LOGOUT':
            return initState
        default:
            return state
    }
}


export default establishment;
import React from 'react';
import { useDispatch ,useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";

//Components
import OptionElement from './OptionElement';
import { FaUserCircle } from "react-icons/fa";
import { Menu, Dropdown } from 'antd';

//Assets
import './sideMenu.css';
import logo from '../../assets/images/brand_color.svg';

function SideMenu() {
  const user_data = useSelector(state=>state.usuario);
  const dispatch = useDispatch();
  let history = useHistory();
  const paths = useLocation().pathname.split('/').slice(1);
  const [section] = paths;

  const menu = (
    <Menu>
      <Menu.Item danger onClick={() => logout()}>Cerrar Sesión</Menu.Item>
    </Menu>
  );

  
  const logout = () => {
    dispatch({
      type: 'LOGOUT',
      payload:{
        id: '',
        name: '',
        email: '',
        token: ''
      }
    });
    history.push('/');
  }
  

  const navigate = (e) => {
    if(e === 'Home'){
      history.push('/home')
    }
    if(e === 'Survey'){
      history.push('/encuestas')
    }
    if(e === 'User'){
      history.push('/usuarios')
    }
    if(e === 'GenerateToken'){
      history.push('/generartoken')
    }
  }

  return (
    <div className="SideMenu">

      <div className="SideMenuHeader">
        <img className="SideLogoHeader" src={ logo } alt="LogoTokencash"/>
      </div>


      <div className="SideMenuBody">
        {/*<OptionElement onClick={()=>{navigate('Home')}} Text="Home" Active={section === 'home' ? true: false} Icon="fa-home" />*/}
        <h6 className="SideSeparator">Menu Principal</h6>
        {
          user_data.role === 1 ? 
          <>
            <OptionElement onClick={()=>{navigate('Home')}} Text="Home" Active={section === 'home' ? true: false} Icon="fa-home" /> 
            <OptionElement onClick={()=>{navigate('Survey')}} Text="Encuestas" Active={section === 'encuestas' ? true: false} Icon="fa-poll-h" /> 
          </>
          :
          <>
          
          </>
          
        }
          <OptionElement onClick={()=>{navigate('GenerateToken')}} Text="Generar Token" Active={section === 'generartoken' ? true: false} Icon="fa-ticket-alt" />
          <h6 className="SideSeparator">Catalogos</h6>
          {
          user_data.role === 1 ? 
          <>
            
          </>
          :
          <>
          
          </>
          
        }
      </div>
      <div className="SideMenuFooter">
      <Dropdown overlay={menu}>
        <p class="FooterTextSide"><FaUserCircle />{user_data.name}</p>
      </Dropdown>
        
      </div>
    </div>
  )
}

export default SideMenu;
class Constans {

    //Login page
    static titleLogin = 'Iniciar sesión';
    static subTitleLogin = 'Ingresa a tu cuenta Tokencash';
    static forgotPasswordLogin = '¿Ha olvidado la contraseña?';
    static policyPrivateLogin = 'Política de privacidad';
    static footerLogin = '© 2021 Tokencash';

}

export default Constans;
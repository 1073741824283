const initState = () => ({
    token: '',
    transt: '',
    amount: '',
    sale: ''
})


function tokens(state = initState(), action) {
    
    switch (action.type) {
        case 'TOKENS':
            return action.payload
        case 'LOGOUT':
            return initState
        default:
            return state
    }
}


export default tokens;
import { combineReducers } from 'redux'
import user from './user'
import keys from './keys'
import establishment from './establishment'
import tokens from './tokens'


export default combineReducers({
    usuario: user,
    keys: keys,
    establishment: establishment,
    tokens: tokens
})
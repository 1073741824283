import React, {useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import {useSelector} from 'react-redux'
import Api from '../../utils/api';

//Components
import { message, Spin, Card } from 'antd';
import CardMap from '../../components/CardMap/CardMap';
import ChartLine from '../../components/ChartLine/CharLine';

//Assets
import './surveydetail.scss';

const api = new Api()

function SurveyDetail() {
 const { slug } = useParams();
 const user = useSelector(state=>state.usuario)

 const [loading, setLoading] = useState(false)
 const [response, setResponse] = useState([])

 useEffect(() => {
    setLoading(true)
    api.surveyDetail(user.token, slug)
    .then(res=>{
      if(res.success){
        console.log(response)
        setResponse(res.success)
      }
      else{
        message.error(res.error);
      }
    })
    .catch(err=>{
      message.error(err);
    })
    .finally(fin=>{
      setLoading(false);
    })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


  return (
    <div className="DetailSurvey">
        {
            loading ?
            <div className="Loading">
            <Spin size="large" />
            </div>
            : 
            <>
            <div className="DetailRow">
              <div className="CardDetail">
                <Card>
                  <h6>{response?.question}</h6>
                  <CardMap />
                </Card>
              </div>
              <div className="CardDetail">
                <Card>
                  <ChartLine res={response?.result} ans={response?.answer}/>
                </Card>
              </div>
            </div>
            <div className="DetailRow">
              <div className="CardDetail">
                <Card>
                
                </Card>
              </div>
              <div className="CardDetail">
                  <Card>
                </Card>
              </div>
            </div>
            </>
            
        }
    </div>
  )
}


export default SurveyDetail;

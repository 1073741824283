import React, {useState, useRef} from 'react';
import {useSelector} from 'react-redux';
import QRCode from 'qrcode.react';
import { Button, message, Collapse } from 'antd';
import Api from '../../utils/api';
import { LoadingOutlined, RedoOutlined, PrinterOutlined, AuditOutlined } from '@ant-design/icons';
import logo from '../../assets/images/logo_tokencompleto.svg';
import {useReactToPrint} from 'react-to-print';

import './tokencard.scss';


class TicketPrint extends React.Component {
    render(){
    let today = new Date();
    let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    return(
    <div className="Tickets">
        <img className="TicketLogo" src={logo} alt="LogoTokencash" />
        <h6>==========================</h6>
        <h6>***** ORIGINAL *****</h6>
        <h6>==========================</h6>
        <h6>Pagos con tokencash fidelidad</h6>
        <h6>${this.props.abonado} MXN, {date+' '+time}</h6>
        <br />
        <div>
            <h6>Importe recibido:  ${this.props.abonado} MXN</h6>
            <h6>Importe faltante:  ${this.props.restante}MXN</h6>
        </div>
        <h6>==========================</h6>
        <h6>GRACIAS POR SU VISITA</h6>
        <h6>{date+' '+time}</h6>
    </div>
    );
}
}

class TicketPayPrint extends React.Component {
    render(){
    let today = new Date();
    let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    return(
    <div className="Tickets">
        <img className="TicketLogo" src={logo} alt="LogoTokencash" />
        <h6>==========================</h6>
        <h6>***** ORIGINAL *****</h6>
        <h6>==========================</h6>
        <h6>Escanea tu código para pagar.</h6>
            <QRCode value={this.props.qr} />
        <h6>Token: {this.props.qr}</h6>
        <h6>==========================</h6>
        <h6>GRACIAS POR SU VISITA</h6>
        <h6>{date+' '+time}</h6>
    </div>
    );
}
}

function Tokencard(props) {
    const api = new Api();
    const [loading, setLoading] = useState(false)
    const [pay, setPay] = useState(0)
    const [completed, setCompleted] = useState(false)
    const establishment = useSelector(state=>state.establishment)
    const {token, transt, amount} = props;
    const componentRef = useRef();
    const componentRef2 = useRef();
    const { Panel } = Collapse;

    const update = () => {
        const dt = {
            'transaccion': transt
        }; 
        setLoading(true)
        api.updateToken(dt ,establishment.token)
        .then(res =>{
            if(res.success){
                //console.log(res.success.payment);
                setPay(res.data.monto_abonado)
                if(res.data.monto_abonado >= amount){
                    setCompleted(true)
                }
            }
            else{
                message.error('Error al refrescar');
            }
          })
        .catch( err => message.error('Error de conexión.'))
      
        .finally( end => setLoading(false) )
    }

    const printOut = useReactToPrint({
        content: () => componentRef2.current
    });

    const printPayCode = useReactToPrint({
        content: () => componentRef.current
    });

  return (
    <>
      <div className={`Tokencard ${completed? 'BorderGreen': ''}`}>
            <div>
                <h4>Token: {token}</h4>

                <h2>Monto: ${amount}</h2>
                <h2 className="abonado">ABONADO: ${pay}</h2>
                <h2 className="restante">RESTANTE: ${pay === 0 ? amount : amount - pay}</h2>
                <div className="BtnSection">
                    <Button className="btn-a" type="primary" size="large" disabled={ loading ? 'disabled' : '' } onClick={(e)=>{update(e)}}>{ loading ? <LoadingOutlined /> : <RedoOutlined />}Actualizar</Button>
                    &nbsp;
                    <Button onClick={printPayCode} type="primary" size="large" ><AuditOutlined />Imprimir Ticket de pago</Button>
                    &nbsp;
                    <Button onClick={printOut} type="primary" size="large" ><PrinterOutlined />Imprimir Comprobante</Button>
                </div>
            </div>
            <div className="qr">
                <QRCode value={token} />
            </div>
            <Collapse className="TicketPreview">
                <Panel header="Ticket Pago" key="1">
                    <TicketPayPrint qr={token} ref={componentRef} />
                </Panel>
                <Panel header="Comprobante de pago" key="2">
                    <TicketPrint abonado={pay} restante={amount - pay} ref={componentRef2} />
                </Panel>
            </Collapse>
            
      </div>
    </>
  )
}

export default Tokencard;
